import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import clsx from 'clsx'
import { useUser } from './providers/useUser'

export default function ChatBox() {
    const { login, logout, sendMessage, sendCat, user } = useUser()
    const [help, setHelp] = useState(false)
    const [inputRef, setInputRef] = useState(null)

    const handleMessage = () => {
        const msg = inputRef.value.trim()
        inputRef.value = ''
        if (msg.length === 0) return // empty input
        if (msg[0] === '/')
            // input is a command
            switch (msg.substring(1).split(' ')[0]) {
                case 'login':
                    login(msg.split(' ')[1])
                    return
                case 'logout':
                    logout()
                    return
                case 'cat':
                    sendCat()
                    return
                default:
                    return
            }
        sendMessage(msg)
    }

    return (
        <>
            {help &&
                ReactDOM.createPortal(
                    <div className="fixed top-0 h-screen w-screen bg-black bg-opacity-50 z-20 grid place-items-center">
                        <div className="bg-gray-200 p-4 flex flex-col max-w-prose w-full">
                            <div className="flex flex-row w-full justify-between items-center text-black font-semibold mb-2">
                                <h1>Help</h1>
                                <span
                                    className="cursor-pointer py-1 px-3 rounded-full bg-red-300"
                                    onClick={() => setHelp(false)}
                                >
                                    X
                                </span>
                            </div>
                            <table className="table-auto border border-gray-400">
                                <thead>
                                    <tr>
                                        <th className="border border-gray-400">
                                            Command
                                        </th>
                                        <th className="border border-gray-400">
                                            Function
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border border-gray-400">
                                            {'/login <username>'}
                                        </td>
                                        <td className="border border-gray-400">
                                            {
                                                'Logs you in as <username> if that name is free. Login sessions last a maximum of 1 hour before the name is automatically freed'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-400">
                                            {'/logout'}
                                        </td>
                                        <td className="border border-gray-400">
                                            {
                                                'Wipes your session. The name will not be able to be reclaimed by anyone (including you) until the 1 hour is up'
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border border-gray-400">
                                            {'/cat'}
                                        </td>
                                        <td className="border border-gray-400">
                                            {
                                                "Sends a cat to everyone, because who doesn't like cats"
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>,
                    document.getElementById('root')
                )}
            <div className="w-full flex flex-row">
                <div className="relative flex-grow">
                    <input
                        ref={(r) => setInputRef(r)}
                        type="text"
                        className={clsx(
                            'w-full px-2 py-2 focus:outline-none',
                            !user && 'placeholder-red-600'
                        )}
                        placeholder={
                            user
                                ? 'What would you like to say?'
                                : '/login <username> to login'
                        }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleMessage()
                        }}
                        autoFocus
                    ></input>
                    <span
                        className="absolute right-2 top-1.5 text-sm rounded-full bg-gray-300 py-1 px-2 cursor-pointer"
                        onClick={() => setHelp(true)}
                    >
                        ?
                    </span>
                </div>
                <button
                    className="px-4 bg-blue-200 hover:bg-blue-300"
                    onClick={handleMessage}
                >
                    Send
                </button>
            </div>
        </>
    )
}
