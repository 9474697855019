import React from 'react'
import { useUser } from './providers/useUser'
import clsx from 'clsx'

function ChatBubble({ children, username, me }) {
    return (
        <div className={clsx('w-full flex flex-col mb-4', me && 'items-end')}>
            <h2 className="text-sm font-semibold">{username}</h2>
            <span
                className={clsx(
                    'rounded-md py-1 px-2 max-w-full w-max',
                    me ? 'bg-blue-200' : 'bg-gray-100'
                )}
            >
                <p>{children}</p>
            </span>
        </div>
    )
}

export default function Chat() {
    const { messages, user } = useUser()
    return (
        <div className="w-full flex-grow bg-gray-200 flex flex-col-reverse px-4 pb-4 overflow-y-scroll">
            <div className="flex flex-col justify-end w-full">
                {messages.map((msg, idx) => (
                    <ChatBubble username={msg[1]} me={msg[1] === user}>
                        {msg[2] === 'message' ? (
                            msg[3]
                        ) : (
                            <img
                                src={`https://cataas.com/cat/${msg[3]}`}
                                alt="cat"
                            />
                        )}
                    </ChatBubble>
                ))}
            </div>
        </div>
    )
}
