import React from 'react'
import Chat from './components/Chat'
import ChatBox from './components/ChatBox'
import User from './components/User'

function App() {
    return (
        <div className="h-screen w-screen flex flex-col items-center bg-gray-300">
            <div className="flex flex-col w-full h-full max-w-5xl">
                <div className="w-full bg-gray-100 border border-gray-300 flex flex-row items-center justify-between py-4 px-6 shadow-sm z-10">
                    <h1 className="font-semibold">
                        Distributed Chat - COMP3211 Coursework 2
                    </h1>
                    <User />
                </div>
                <Chat />
                <ChatBox />
            </div>
        </div>
    )
}

export default App
