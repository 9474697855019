import React, { useState, useContext, createContext, useEffect } from 'react'
import * as jwtUtil from 'jsonwebtoken'

const userContext = createContext()

export function UserProvider(props) {
    const user = useProvideUser()
    return (
        <userContext.Provider value={user}>
            {props.children}
        </userContext.Provider>
    )
}

export const useUser = () => {
    return useContext(userContext)
}

function useProvideUser() {
    const [JWT, setJWT] = useState(localStorage.getItem('jwt'))
    const [messages, setMessages] = useState([])

    const login = (username) =>
        fetch('https://auth.rakagunarto.workers.dev/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username,
            }),
        })
            .then((res) => res.json())
            .then((token) => {
                setJWT(token)
                localStorage.setItem('jwt', token)
            })
    const logout = () => {
        fetch('https://auth.rakagunarto.workers.dev/session', {
            method: 'DELETE',
            body: JSON.stringify({ token: JWT }),
        })
        setJWT(null)
        localStorage.removeItem('jwt')
    }

    const sendMessage = (msg) =>
        JWT &&
        fetch('https://chat.watermelon33.workers.dev/messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                jwt: JWT,
                user: jwtUtil.decode(JWT, { complete: true }).payload.username,
                type: 'message',
                content: msg,
            }),
        }).then(fetchMessages)

    const sendCat = () =>
        JWT &&
        fetch('https://cataas.com/cat?json=true')
            .then((res) => res.json())
            .then(({ _id }) =>
                fetch('https://chat.watermelon33.workers.dev/messages', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        jwt: JWT,
                        user: jwtUtil.decode(JWT, { complete: true }).payload
                            .username,
                        type: 'cat',
                        content: _id,
                    }),
                }).then(fetchMessages)
            )

    const fetchMessages = () =>
        fetch('https://chat.watermelon33.workers.dev/messages', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((resp) => resp.json())
            .then((data) => setMessages(data.messages))

    // get messages every 10 seconds
    useEffect(() => {
        // fetch once first
        fetchMessages()
        const interval = setInterval(fetchMessages, 10000)
        return () => clearInterval(interval)
    }, [])

    // Return the user auth methods
    return {
        login,
        logout,
        sendMessage,
        sendCat,
        messages,
        get user() {
            if (!JWT) return null
            const data = jwtUtil.decode(JWT, { complete: true })
            return data.payload.username
        },
    }
}
