import React from 'react'
import { useUser } from './providers/useUser'

export default function User() {
    const { user } = useUser()

    return user ? (
        <p className="text-xs px-2 py-1 bg-green-300 rounded">Hello, {user}!</p>
    ) : (
        <p className="text-xs px-2 py-1 bg-red-300 rounded">Not logged in</p>
    )
}
